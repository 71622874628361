.lia-solution-btn {
  display: flex;
  align-items: center;
  gap: 5px;

  &.lia-is-solution:not(:hover) {
    background-color: var(--lia-forum-solved-color);

    .lia-solution-text {
      color: var(--lia-bs-white);
    }
    .lia-check-icon,
    .lia-close-icon {
      fill: var(--lia-bs-white);
    }
  }
}

.lia-solved-badge {
  font-size: var(--lia-font-size-xxs);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-white);
  background-color: var(--lia-forum-solved-color);
  line-height: 1.454545454;
}

.lia-close-icon {
  height: 10px;
  width: 10px;
  margin: 0 7px 0 5px;
}
